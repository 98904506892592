import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const getData = graphql`
  query {
    directus {
      siteMetaData {
        id
        title
        description
        author
        image {
          id
        }
        twitterUsername
        siteUrl
      }
    }
  }
`;

const SEO = ({ title, description }) => {
  const { directus } = useStaticQuery(getData);

  const {
    description: siteDesc,
    title: siteTitle,
    siteUrl,
    image,
    twitterUsername,
  } = directus.siteMetaData;
  const finalImage = `https://sfb.clockworkbee.co.uk/assets/${image.id}`;

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDesc} />
      <mete name="image" content={finalImage} />
      {/* Facebook card */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:image" content={`${siteUrl}${finalImage}`} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      {/* Twitter card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={siteDesc} />
      <meta name="twitter:image" content={`${siteUrl}${finalImage}`} />
    </Helmet>
  );
};

export default SEO;
