/* eslint-disable no-unused-vars */
import React from 'react';
import { FaFacebook, FaTwitterSquare, FaSquarespace } from 'react-icons/fa';

export default [
  {
    icon: <FaFacebook />,
    url: 'https://www.facebook.com/scoutingforbands',
  },
  // {
  //   icon: <FaTwitterSquare />,
  //   url: "https://twitter.com",
  // },
  // {
  //   icon: <FaSquarespace />,
  //   url: "https://twitter.com",
  // },
];
