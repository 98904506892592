import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import * as styles from '../css/footer.module.css';

import links from '../constants/links';
import socialIcons from '../constants/social-icons';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.links}>
      {links.map((item, index) => (
        <AniLink fade key={`ft_${index}`} to={item.path}>
          {item.text}
        </AniLink>
      ))}
    </div>
    <div className={styles.icons}>
      {socialIcons.map((item, index) => (
        <a
          key={`sc_${index}`}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.icon}
        </a>
      ))}
    </div>
    <a
      style={{ color: '#a1a13f', fontSize: '9px' }}
      href="https://clockworkbee.co.uk"
      target="_blank"
      className={styles.copyright}
      rel="noreferrer"
    >
      copyright &copy; Clockwork Bee {new Date().getFullYear()}
    </a>
  </footer>
);

export default Footer;
